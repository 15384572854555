const Socials = () => {
    return (
        <ul className="socials">
            <li>
                <a href="https://instagram.com/supermilk4u" target="_blank">
                    <i className="fa-brands fa-instagram"></i>
                </a>
            </li>
            <li>
                <a href="https://facebook.com/supermilk4u" target="_blank">
                    <i className="fa-brands fa-facebook"></i>
                </a>
            </li>
            <li>
                <a href="https://open.spotify.com/artist/2GtvFrvgJjN9biM7KWUB5P" target="_blank">
                    <i className="fa-brands fa-spotify"></i>
                </a>
            </li>
            <li>
                <a href="https://music.apple.com/artist/supermilk/1458425899" target="_blank">
                    <i className="fa-brands fa-itunes-note"></i>
                </a>
            </li>
            <li>
                <a href="https://supermilk4u.bandcamp.com" target="_blank">
                    <i className="fa-brands fa-bandcamp"></i>
                </a>
            </li>
        </ul>
    )
}
export default Socials