import Polaroids from "../modules/polaroids.jsx";

const AboutPage = () => {
    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-12 text-align-center">
                    <h1>About</h1>
                </div>
                <div className="col-md-10 col-lg-8 mt-4 mb-4">
                    <img src="/images/supermilk-band-photo.jpg" width={848} height={565} className="fluid" />
                </div>
                <div className="col-md-10 col-lg-9 mb-2">
                    <h3 className="mb-2">
                    Having recently unveiled their sixth release in just four short years, North London based indie-punks <strong>SUPERMILK</strong> have never been a band to sit on their hands. Instead, they have garnered a reputation within the UK’s thriving DIY music scene for their prolific output, tight live shows, and uncompromising mix of angular post-punk and fuzzy 90s-indebted indie rock. It’s this trademark sound which is more realised than ever on the group’s latest full-length effort <strong>HIGH PRECISION GHOSTS</strong>, produced by <strong>Me Rex</strong>'s <strong>Rich Mandell</strong> and out now via Bristol’s <strong>Specialist Subject Records</strong>.
                    </h3>
                    <p>Considering their reluctance to slow down, it’s no surprise that <strong>HIGH PRECISION GHOSTS</strong> - the project’s third studio LP and first as a full band - was mixed, mastered and sent off to the pressing plant barely a month after recording started. Despite not having influenced the content of the album, it would be impossible to deny that band leader <strong>Jake Popyura</strong>’s recent diagnosis of <strong>Amyotrophic Lateral Sclerosis</strong>, a rare and fatal motor neuron disease, has played a vital part in this ‘do it yesterday’ approach to making music: <em>“I’ve never wanted there to be too much of a lull in activity with any band I play in,” says the bassist and lead vocalist, “and I’ve always liked it when things move at pace… but following my diagnosis, making music that I love with people I love feels more important than ever, and I want to make sure not a second is wasted.“</em></p>
                    <p>Formerly Popyura’s studio-only solo endeavour, SUPERMILK started life in 2017 as an outlet for songs that didn’t suit his previous group, the raucous power-trio <strong>DOE</strong>. Fast forward seven years and SUPERMILK are now entering their third year as a full band, featuring guitarist/vocalist <strong>Em Foster</strong>, drummer <strong>Jason Cavalier</strong>, and newest recruit <strong>Charlie Jamison</strong> taking over guitar duties from the recently departed <strong>Sophie MacKenzie</strong>. The last two years have seen the band playing for audiences far and wide, including stints with <strong>Cheekface</strong>, <strong>Speedy Ortiz</strong>, <strong>Martha</strong>, <strong>Lande Hekt</strong> and as tour support for US Emo stalwarts <strong>Algernon Cadwallader</strong>.</p>
                    <p>As with 2020’s <strong>DEATH IS THE BEST THING FOR YOU NOW</strong> and 2021’s <strong>FOUR BY THREE</strong>, <strong>HIGH PRECISION GHOSTS</strong> follows a loose theme, as Popyura explains: <em>“Most of the songs are vaguely related to the remnants of toxic relationships, both romantic and platonic, and how they can often stick around like ghosts, continuing to haunt you when you least expect it.”</em> <strong>ROBOT TALK</strong>, a track which is as much XTC as it is Hot Club de Paris, chronicles a desperate attempt to get through to a partner with a radically different communication style. The Weezeresque <strong>CHRISTINE</strong> details a villager’s love/hate relationship with the local witch. Off-kilter rocker <strong>DO NOT PASS GO</strong> furiously jolts between time signatures to capture the frenzy of a nervous breakdown following a friendship ending.</p>
                    <p>And it’s the album’s second single - the short, sharp and bitter-sweet post-punk belter <strong>MANY THANKS</strong> - that sees Popyura reflect on his own unhealthy relationship with releasing music: <em>“I spiral into a pretty big depression whenever we release new stuff, every time without fail. The only way to keep sane is to make music for yourself, be your own band's biggest fan and f**k everyone else... but that's often easier said than done. In a nutshell: 'Comparison is the thief of joy.'</em></p>

                </div>
            </div>
            <Polaroids />
        </div>
    )
};
export default AboutPage;