import Pages from '../pages/pages.jsx';

export const slugFromPath = () => {
    const path = location.pathname.substring(1);
    const page = Object.keys(Pages).find(key => Pages[key].path === path);
    return page;
}

export const setInitPage = () => {
    let initPage;
    const url = window.location.href;
    const res = url.split("/");
    const urlSlug = res[res.length-1];
  
    if ( urlSlug !== '' ) {
      initPage = urlSlug
    } else {
      initPage = 'landing'
    }
    return initPage;
}