import Polaroids from "../modules/polaroids.jsx";

const ShowsPage = () => {
    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-12 text-align-center mb-4">
                    <h1>Shows</h1>
                </div>
                <div className="col-md-9 text-align-center mb-2">
                
                <p className="mb-4">No upcoming shows</p>
                <h3 className="mb-2">Previous</h3>
                <p><strong>05.09.24</strong><br />London // The Victoria, Dalston<br />w/ Mammoth Penguins</p>

                <p><strong>04.09.24</strong><br />Portsmouth // Edge of the Wedge<br />w/ ?</p>

                <p><strong>03.09.24</strong><br />Brighton // Daltons<br />w/ Definitely Not Haunted</p>

                <p><strong>16.06.24</strong><br />Margate // Where Else?<br />w/ Long Neck</p>

                <p><strong>15.06.24</strong><br />Bristol // Rough Trade<br />w/ Muncle, Olive Featherstone</p>

                <p><strong>14.06.24</strong><br />Manchester // Fuel Bar & Cafe<br />w/ Hail Spraytain</p>

                <p><strong>13.06.24</strong><br />Sunderland // Pop Recs<br />w/ Fortitude Valley, Gelfling</p>

                <p><strong>12.06.24</strong><br />Oxford // Common Ground<br />w/ Top Shortage, Nothing Dreams</p>

                <p><strong>30.03.24</strong><br />Manchester // YES (Pink Room)<br />Manchester Punk Fest</p>

                <p><strong>28.03.24</strong><br />London // The Finsbury<br />w/ Honey Joy, Brunch</p>

                <p><strong>27.03.24</strong><br />Birmingham // Hare & Hounds<br />w/ Cheekface</p>

                <p><strong>24.02.24</strong><br />London // MOTH Club<br />w/ Speedy Ortiz</p>

                <p><strong>23.10.23</strong><br />London // The Lexington<br />w/ Lande Hekt, Charley Stone</p>

                <p><strong>10.08.23</strong><br />London // The Victoria, Dalston<br />w/ Publicity Department</p>

                <p><strong>16.07.23</strong><br />Manchester // Castle Hotel<br />w/ Fortitude Valley</p>

                <p><strong>15.07.23</strong><br />Oxford // Tap Social<br />w/ Fortitude Valley</p>

                <p><strong>14.07.23</strong><br />Norwich // The Crypt<br />w/ Fortitude Valley</p>

                <p><strong>13.07.23</strong><br />London // Signature Brew<br />w/ Fortitude Valley</p>

                <p><strong>14.06.23</strong><br />Bristol // The Fleece<br />w/ Algernon Cadwallader</p>

                <p><strong>10.06.23</strong><br />Leeds // Wharf Chambers<br />w/ Algernon Cadwallader</p>

                <p><strong>09.06.23</strong><br />Birmingham // Hare & Hounds<br />w/ Algernon Cadwallader</p>

                <p><strong>08.06.23</strong><br />Brighton // Chalk<br />w/ Algernon Cadwallader</p>

                <p><strong>07.06.23</strong><br />London // The Garage<br />w/ Algernon Cadwallader</p>

                <p><strong>09.03.23</strong><br />London // Shacklewell Arms<br />w/ Felicette, EB Hill</p>

                <p><strong>19.11.22</strong><br />London // EartH<br />w/ Martha, BigFatBig</p>

                <p><strong>16.10.22</strong><br />London // Matchstick Piehouse<br />Till The Fest 2</p>

                <p><strong>27.08.22</strong><br />Bristol // Exchange Basement<br />Specialist Subject weekender</p>

                <p><strong>25.08.22</strong><br />Brighton // Hope & Ruin<br />w/ Regal Cheer</p>

                <p><strong>22.08.22</strong><br />London // The Victoria, Dalston<br />w/ Dream Phone, Trust Club</p>

                <p><strong>03.05.22</strong><br />London // Shacklewell Arms<br />w/ Nervus</p>

                <p><strong>02.05.22</strong><br />Bristol // Exchange (Basement)<br />w/ Nervus</p>

                <p><strong>01.05.22</strong><br />Liverpool // Black Cat Cafe<br />w/ Nervus</p>

                <p><strong>30.04.22</strong><br />Dundee // Rad Apples<br />w/ Nervus</p>

                <p><strong>29.04.22</strong><br />Leeds // Wharf Chambers<br />w/ Nervus</p>

                <p><strong>28.04.22</strong><br />Margate // Elsewhere<br />w/ Nervus</p>

                <p><strong>25.02.22</strong><br />London // The Victoria, Dalston<br />w/ Fightmilk, Milk Crimes</p>

                <p><strong>24.02.22</strong><br />Oxford // Fusion Arts Cafe<br />w/ Dogmilk, Wince, Junk Whale</p>

                <p><strong>08.02.22</strong><br />London // New Cross Inn<br />w/ Honey Joy, Uzumaki</p>

                </div>
            </div>
            <Polaroids />
        </div>
    )
};
export default ShowsPage;