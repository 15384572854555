// Hooks
import { useEffect } from "../hooks/hooks.jsx";

const LandingPage = ({changePage}) => {

	// Landing page controls to be fired once page is rendered
	useEffect(() => {
		const 	landingContainer = document.querySelector( '.sm-landing-container' ),
				enterButton = document.querySelector( '.js-enter-button' ),
				enterFill = document.querySelector( '.js-enter-fill' ),
				nav = document.querySelector( 'nav' )

		// Fade in landing page
		landingContainer.classList.add( 'sm-opacity-1' )

		// Add loaded class for additional animations after timeout
		setTimeout(
			() => {
				landingContainer.classList.add( 'loaded' )
			}, 1500
		)

		enterButton.addEventListener( 'mouseover', ( event ) => {
			// Enter button - fill on mouseover
			enterFill.classList.add( 'over' )
		})

		enterButton.addEventListener( 'mouseout', ( event ) => {
			// Enter button - empty on mouseout
			enterFill.classList.remove( 'over' )
		})
	
		enterButton.addEventListener( 'click', ( event ) => {
			// Enter button - animate and render Home page on click
			enterFill.classList.add( 'cover' );
			landingContainer.classList.remove( 'loaded' )
			landingContainer.classList.add( 'done' )
			setTimeout(
				() => {
					landingContainer.classList.add( 'd-none' )
					nav.classList.remove( 'hide' )
					changePage('home');
					history.replaceState( null, null, '/home' );
				}, 1000
			)
		})
	}, [])

    return (

    	<div className="sm-landing-container sm-opacity-0 ps-4 pe-4">

			<div className="sm-landing-el-wrapper">

				<svg height="0" width="0" className="" viewBox="0 0 595.22 233.33">

					<defs>

						<clipPath id="svgPath" clipPathUnits="objectBoundingBox" transform="scale(0.00167, 0.00429)">

							{/* Logo paths */}
							<path d="M366.89,87.22c1.67-4.32,3.16-8.31,4.74-12.27c1.65-4.14,3.99-7.71,8.13-9.82c1.16-0.59,2.15-1.51,3.33-2.04
								c0.94-0.43,2.04-0.59,3.08-0.69c0.89-0.09,1.81,0.14,2.71,0.11c2.97-0.09,4.3,1,4.83,3.96c0.5,2.83,1.02,5.65,1.57,8.46
								c1.14,5.83,2.66,11.65,2.51,17.61c-0.17,6.8,2.02,13.13,3.23,19.65c1.36,7.37,2.71,14.74,4.33,22.06
								c1.02,4.58,2.54,9.05,3.84,13.57c1.74,6.01,3.5,12.01,5.24,18.02c0.97,3.34,0.08,5.03-3.14,6.23c-2.06,0.77-4.16,1.06-6.35,0.92
								c-2.81-0.18-4.97-1.39-5.94-4.08c-1.16-3.22-2.06-6.53-3.12-9.79c-3.25-9.97-4.57-20.36-6.74-30.57
								c-1.2-5.65-2.63-11.25-3.88-16.89c-0.28-1.27-0.23-2.6-0.32-3.91c-0.11-1.62-0.16-3.25-0.33-4.87c-0.45-4.23-0.98-8.46-1.43-12.7
								c-0.09-0.8-0.23-1.41-1.18-1.47c-1.76,3.02-2.64,6.29-3.17,9.7c-0.43,2.81-1.11,5.55-2.87,7.9c-3.12,4.16-7.44,4.47-10.79,0.49
								c-5.8-6.9-11.45-13.94-17.17-20.91c-0.49-0.6-1.09-1.1-1.76-1.77c-0.14,0.36-0.24,0.49-0.24,0.63c-0.37,10.8-0.86,21.6-1.04,32.41
								c-0.1,5.7,0.57,11.4,0.44,17.1c-0.16,7.13-0.76,14.25-1.21,21.37c-0.24,3.74-0.55,7.48-0.86,11.21c-0.08,0.95-0.17,1.92-0.41,2.83
								c-0.77,2.96-2.85,4.43-5.91,4.29c-2.77-0.13-4.85-1.83-5.16-4.73c-0.33-3.12-0.25-6.28-0.39-9.42c-0.07-1.51-0.24-3.01-0.36-4.52
								c-0.61-7.45-0.52-14.91-0.01-22.35c0.98-14.22,0.83-28.47,1.04-42.71c0.09-6.1,0.53-12.19,0.92-18.28c0.25-3.9,4.04-6.65,8.19-5.92
								c1.37,0.24,2.79,0.78,3.96,1.53c7.59,4.89,13.91,11.2,19.77,18.01C365.46,86.16,366.13,86.59,366.89,87.22z"/>
							<path d="M278.12,146.05c0,3.51-0.02,7.02,0.01,10.53c0.01,1.59,0.08,3.19,0.2,4.78c0.28,3.94-1.42,6.75-4.81,8.65
								c-1.74,0.98-2.92,0.87-4.51-0.35c-2.36-1.8-3.06-4.34-3.42-7.11c-0.84-6.57-0.41-13.12-0.17-19.7c0.23-6.25,0.1-12.52-0.16-18.77
								c-0.39-9.2-1.07-18.39-1.56-27.59c-0.34-6.33-0.58-12.67-0.85-19c-0.21-4.93-0.3-9.88-0.62-14.8c-0.24-3.64,0.76-6.59,3.86-8.7
								c1.58-1.08,3.21-1.26,5-0.61c6.56,2.37,13.14,4.7,19.71,7.07c6.79,2.45,11.99,6.71,15.16,13.3c0.5,1.04,1.15,2,1.75,2.99
								c4.68,7.63,5.88,15.92,3.89,24.5c-1.1,4.78-4.02,8.84-7.28,12.5c-1.1,1.24-1.27,2.37-0.87,3.79c1.5,5.41,3.07,10.81,4.49,16.25
								c2.21,8.47,3.52,17.11,4.32,25.8c0.22,2.42-0.32,4.92-0.66,7.36c-0.18,1.27-1.74,2.33-3.22,2.45c-3.84,0.33-8-2.76-8.89-6.61
								c-1.01-4.43-2.06-8.85-2.99-13.29c-0.37-1.79-0.47-3.63-0.72-5.44c-0.09-0.62-0.13-1.3-0.4-1.85c-2.14-4.26-2.88-8.84-3.15-13.53
								c-0.07-1.23-0.09-2.47-0.23-3.7c-0.16-1.38-0.92-1.92-2.26-1.52c-3.33,0.99-6.64,2.01-9.93,3.12c-1.2,0.4-1.75,1.39-1.74,2.72
								c0.02,5.58,0.01,11.17,0.01,16.75C278.09,146.05,278.1,146.05,278.12,146.05z M274.74,76.19c0.09,0.01,0.18,0.03,0.26,0.04
								c0.78,9.45,1.55,18.9,2.34,28.35c0.11,1.33,0.67,1.77,2.14,1.53c4.85-0.81,9.64-1.89,14.15-3.89c4.24-1.88,6.22-5.6,5.63-10.18
								c-0.48-3.72-1.86-7.07-4.06-10.09c-2.76-3.81-6.5-6.44-10.67-8.47c-1.96-0.95-4-1.79-6.09-2.42c-1.73-0.53-2.27-0.15-2.86,1.57
								C275.19,73.77,275.01,75,274.74,76.19z"/>
							<path d="M547.77,97.96c0.84-1.22,1.64-2.13,2.16-3.19c2.74-5.53,6.98-9.6,12.29-12.55c3.19-1.77,5.52-4.42,8.1-6.86
								c4.79-4.52,10.48-7.21,16.99-8.16c2.04-0.3,4.73,1.46,5.9,3.8c1.05,2.1,0.75,4.08-1.07,5.76c-2.91,2.68-5.94,5.22-8.83,7.91
								c-7.95,7.37-15.11,15.49-22.33,23.54c-2.7,3.01-2.71,3.13-0.68,6.55c2.74,4.63,5.02,9.54,7.9,14.07c3.08,4.84,6.69,9.34,9.96,14.07
								c3.2,4.62,6.24,9.36,9.42,14c1.03,1.51,2.24,2.91,3.47,4.28c2.61,2.9,3.53,6.49,4.12,10.16c0.38,2.36-1.68,5.18-4.07,6.05
								c-2.63,0.97-5.23,0.25-6.81-1.98c-0.64-0.9-1-2.02-1.69-2.88c-1.24-1.56-2.59-3.03-3.94-4.49c-5.61-6.07-10.31-12.75-14.26-20.03
								c-2.52-4.65-5.62-8.99-8.47-13.46c-1.59-2.49-3.2-4.96-4.85-7.41c-0.24-0.36-0.77-0.53-1.16-0.79c-0.18,0.43-0.55,0.88-0.52,1.29
								c0.26,2.77,0.66,5.54,0.92,8.31c0.35,3.77,0.63,7.54,0.92,11.31c0.49,6.28,1.05,12.56,1.4,18.85c0.13,2.41-0.03,4.88-0.41,7.26
								c-0.52,3.26-2.78,4.62-6.03,3.99c-3.4-0.65-5.49-2.9-5.72-6.26c-0.48-7.16-0.83-14.33-1.38-21.49c-0.49-6.32-1.16-12.63-1.82-18.93
								c-0.97-9.36-2.13-18.71-2.96-28.08c-0.45-5.16-0.35-10.36-0.54-15.54c-0.28-7.33-0.7-14.66-0.83-21.99
								c-0.04-2.22,0.68-4.46,1.14-6.67c0.11-0.55,0.51-1.09,0.9-1.53c2.59-2.96,7.75-2.14,9.07,1.67c0.88,2.56,1.25,5.35,1.46,8.07
								c0.4,5.33,0.47,10.67,0.77,16.01c0.25,4.38,0.63,8.75,0.98,13.12C547.33,96.4,547.56,97.03,547.77,97.96z"/>
							<path d="M145.85,125.24c0-4.35,0.05-8.69-0.01-13.04c-0.12-7.94-0.37-15.87-0.44-23.81c-0.05-5.93,0.49-11.83,2.92-17.33
								c1.44-3.25,1.88-6.64,2.34-10.08c0.36-2.67,1.59-3.89,4.24-4.24c4.7-0.61,9.13,0.2,13.32,2.46c1.06,0.57,2.26,0.95,3.45,1.19
								c2.51,0.5,4.8,1.49,6.94,2.84c2.26,1.42,4.56,2.81,6.73,4.36c0.98,0.7,1.77,1.71,2.55,2.66c1.58,1.9,3.04,3.9,4.66,5.77
								c4.24,4.91,6.67,10.64,7.79,16.96c1.02,5.76,1.39,11.57,1.03,17.4c-0.39,6.39-3.53,11.37-8.97,14.6c-7.92,4.7-16.33,8.2-25.68,8.85
								c-1.51,0.11-3.03,0.07-4.55,0.05c-1.96-0.03-2.63,0.57-2.64,2.5c-0.01,2.27,0.03,4.55,0,6.82c-0.09,6.68-0.14,13.36-0.36,20.04
								c-0.05,1.54-0.47,3.17-1.14,4.57c-1.75,3.69-6.77,4.48-9.77,1.68c-1.15-1.07-1.68-2.4-1.68-3.96c0-2.99,0.08-5.98-0.02-8.97
								c-0.26-8.17-0.6-16.34-0.87-24.51c-0.07-2.27-0.01-4.55-0.01-6.82C145.73,125.24,145.79,125.24,145.85,125.24z M163.72,71.39
								c-1.45-0.03-1.93,0.38-2.1,1.63c-0.06,0.43-0.07,0.87-0.1,1.31c-0.37,6.4-0.81,12.81-1.1,19.21c-0.29,6.45-0.45,12.9-0.65,19.35
								c-0.04,1.23,0.05,2.48-0.08,3.7c-0.17,1.59,0.35,2.18,1.93,1.88c1.45-0.27,2.91-0.5,4.34-0.87c3.47-0.88,6.98-1.66,10.37-2.79
								c2.68-0.89,4.46-2.93,5.08-5.76c1.14-5.13,2.15-10.29,3.36-15.4c0.63-2.68,0.12-5.06-1.23-7.33c-2.54-4.24-5.63-8.04-9.64-11
								C170.82,73.06,167.35,71.74,163.72,71.39z"/>
							<path d="M69.44,100.07c-0.09-6.86-0.22-13.72-0.26-20.58c-0.02-3.35,0.08-6.7,0.2-10.05c0.16-4.68,3.57-7.52,7.94-6.67
								c2.43,0.47,3.78,2.15,4.2,4.39c0.45,2.37,0.8,4.83,0.7,7.23c-0.29,6.99,0.07,13.96,0.47,20.93c0.65,11.35,1.55,22.69,1.89,34.04
								c0.18,5.9,1.7,11.39,3.36,16.92c0.26,0.88,0.5,1.77,0.81,2.63c1.09,3.02,3.45,4.56,6.66,4.38c6.45-0.37,10.76-4.16,11.82-10.48
								c1.05-6.29,2.2-12.57,2.95-18.9c0.43-3.66,0.17-7.4,0.21-11.1c0.05-4.82,0.09-9.64,0.13-14.47c0.01-0.72,0.07-1.43,0.07-2.15
								c0.02-8.7,0.04-17.4,0.04-26.11c0-2.25,0.48-4.36,1.65-6.28c2.22-3.63,7.14-3.63,9.22,0.09c2.1,3.75,3.21,7.79,2.94,12.15
								c-0.58,9.44-0.76,18.88-0.2,28.34c0.44,7.38-0.26,14.75-0.97,22.1c-0.64,6.6-1.44,13.18-3.53,19.5c-1.19,3.58-2.62,7.11-4.3,10.49
								c-4,8.05-10.81,11.43-19.62,10.92c-2.85-0.17-5.76-0.49-8.5-1.25c-6.46-1.8-10.28-6.44-12.41-12.59
								c-2.7-7.79-3.98-15.85-4.31-24.05c-0.4-9.8-0.68-19.61-1.01-29.42C69.52,100.07,69.48,100.07,69.44,100.07z"/>
							<path d="M26.88,171.98c-7.46-0.16-14.5-1.77-21.13-5.17c-3.41-1.75-5.13-4.42-5.03-8.25c0.1-4.01,3.06-6.39,6.95-5.3
								c2.05,0.58,3.99,1.59,5.88,2.6c7.27,3.89,14.59,3.81,22,0.51c1.57-0.7,3.15-1.36,4.72-2.05c1.42-0.62,2.44-1.59,2.95-3.11
								c2.69-7.9,1.58-15.49-1.94-22.85c-1.75-3.66-4.57-6.43-7.94-8.61c-5.88-3.81-12.23-6.72-18.63-9.52c-1.53-0.67-3.02-1.45-4.57-2.1
								c-5.53-2.32-8.22-6.85-9.6-12.34c-1-3.97-0.49-7.92,0.61-11.81c0.61-2.15,1.26-4.28,1.92-6.41c1.66-5.4,5.19-9.05,10.58-10.66
								c6.12-1.83,12.31-3.35,18.67-4.18c3.88-0.51,7.64,0.09,11.4,0.87c0.7,0.15,1.4,0.3,2.11,0.46c3,0.66,4.9,3.29,4.79,6.64
								c-0.09,2.73-2.32,5.11-5.24,5.53c-0.67,0.1-1.38,0.2-2.03,0.08c-3.7-0.71-7.3-0.13-10.92,0.6c-4.11,0.83-8.27,1.43-12.32,2.46
								c-3.84,0.98-5.59,3.95-4.95,7.71c0.38,2.23,1.71,3.9,3.56,5.04c2.13,1.31,4.36,2.5,6.65,3.51c4.29,1.89,8.51,3.91,12.15,6.87
								c1.97,1.6,3.71,3.51,5.4,5.41c3.28,3.7,6.49,7.47,9.66,11.26c3.93,4.7,5.83,10.28,6.94,16.2c0.64,3.41,1.49,6.79,1.21,10.3
								c-0.47,6.09-2.48,11.5-6.99,15.81c-1.24,1.19-2.48,2.38-3.74,3.54c-3.92,3.58-8.68,5.3-13.84,6.01
								C33.08,171.45,29.98,171.66,26.88,171.98z"/>
							<path d="M207.66,136.4c0-5.55-0.04-11.09,0.01-16.64c0.06-7.19-0.54-14.32-1.6-21.44c-0.73-4.88-0.85-9.85-1.43-14.76
								c-0.57-4.74-1.23-9.48-2.14-14.17c-0.98-5.04,2.09-9.28,7.1-8.97c4.15,0.26,8.09-0.55,12.09-1.3c5.37-1.01,10.76-1.92,16.16-2.82
								c1.7-0.28,3.34,0.11,4.88,0.87c3.46,1.69,4.53,5.5,2.26,8.63c-2.18,3.01-5.36,4.41-8.98,4.9c-5.46,0.73-10.92,1.46-16.38,2.2
								c-2.29,0.31-2.74,1.34-2.27,3.58c0.52,2.47,0.72,5.03,0.85,7.56c0.43,8.48,0.77,16.97,1.14,25.46c0.1,2.24,0.61,2.65,2.81,2.31
								c3.95-0.61,7.89-1.23,11.85-1.75c4.77-0.63,7.87,1.88,8.07,6.39c0.1,2.2-1.07,3.67-2.72,4.89c-1.92,1.42-4.22,1.6-6.45,2.02
								c-3.57,0.68-7.14,1.32-10.71,1.99c-1.5,0.28-1.94,0.82-1.92,2.52c0.05,4.91,0.18,9.81,0.19,14.72c0.01,3.59-0.1,7.18-0.14,10.77
								c-0.01,0.96,0,1.92,0.08,2.87c0.09,0.99,1.15,1.69,2.08,1.36c3.03-1.09,6.08-0.98,9.04,0.06c3.95,1.39,7.83,2.96,11.73,4.47
								c0.44,0.17,0.88,0.39,1.27,0.67c0.72,0.51,1.8,0.83,1.66,1.97c-0.16,1.3-0.87,2.39-2.12,2.83c-7.31,2.58-14.65,5.02-22.57,4.9
								c-4.33-0.06-8.32-0.81-11.5-4.08c-1.63-1.68-2.41-3.59-2.41-5.91c0.02-8.58,0.01-17.16,0.01-25.74c0-0.12,0-0.24,0-0.36
								C207.61,136.4,207.64,136.4,207.66,136.4z"/>
							<path d="M473.77,119.53c-0.29-8.69-0.69-17.37-0.84-26.06c-0.1-6.1,0.22-12.2,0.21-18.3c0-2.38-0.41-4.76-0.64-7.13
								c-0.29-3.02,0.37-5.71,2.8-7.76c1.74-1.46,3.71-1.84,5.83-1.06c2.17,0.8,3.4,2.42,3.75,4.71c1.21,7.92,1.33,15.86,0.96,23.86
								c-0.22,4.64,0.32,9.31,0.53,13.97c0.3,6.73,0.67,13.46,0.88,20.19c0.3,9.73,0.47,19.46,0.72,29.19c0.06,2.43,0.18,4.86,0.29,7.29
								c0.12,2.45,0.94,3.22,3.36,3.08c4.11-0.23,8.21-0.65,12.32-0.69c2.25-0.02,4.55,0.36,6.74,0.9c4.42,1.11,5.85,6.24,2.74,9.6
								c-2.95,3.2-6.62,5.12-11.01,5.01c-6.9-0.18-13.81-0.51-20.7-1.03c-4.91-0.37-8.09-4.19-8.15-9.3c-0.05-3.71-0.01-7.42-0.01-11.14
								c0-11.77,0-23.55,0-35.32C473.63,119.53,473.7,119.53,473.77,119.53z"/>
							<path d="M450.2,75.27c0,1.52-0.01,3.03,0,4.55c0.1,17.34,0.23,34.68,0.31,52.02c0.02,5.19-0.16,10.37-0.13,15.56
								c0.02,3.55,0.41,7.1,0.33,10.64c-0.06,2.77-0.42,5.56-0.96,8.28c-0.62,3.11-3.03,4.79-6.29,4.79c-2.52,0.01-5.15-2.03-5.81-4.73
								c-0.38-1.58-0.54-3.22-0.64-4.85c-0.51-8.68-1.14-17.35-1.39-26.04c-0.18-6.17,0.21-12.36,0.25-18.55c0.05-7.74,0.07-15.48,0-23.22
								c-0.07-7.42-0.3-14.84-0.41-22.25c-0.05-3.32,1.38-5.96,4.11-7.8c0.71-0.48,1.75-0.89,2.54-0.75c1.91,0.32,3.82,0.82,5.62,1.54
								c1.52,0.6,1.94,2.17,2.1,3.67c0.24,2.38,0.37,4.77,0.55,7.15C450.32,75.26,450.26,75.26,450.2,75.27z"/>

							{/* Enter paths */}
							<path className="path-enter path-chevron-left" d="M187.26,224.45l13.16-5.07l-13.16-5.07v-6.76l21.65,9v5.65l-21.65,8.98V224.45z"/>
							<path className="path-enter path-e" d="M226.79,205.41h23.12v5.96h-14.47v4.44h13.43v5.69h-13.43v5.5h14.89v6.32h-23.54V205.41z"/>
							<path className="path-enter path-n" d="M255.03,205.41h8.06l10.51,15.45v-15.45h8.13v27.92h-8.13L263.14,218v15.33h-8.11V205.41z"/>
							<path className="path-enter path-t" d="M285.5,205.41h26.22v6.89h-8.8v21.02h-8.63V212.3h-8.8V205.41z"/>
							<path className="path-enter path-e2" d="M315.61,205.41h23.12v5.96h-14.47v4.44h13.43v5.69h-13.43v5.5h14.89v6.32h-23.54V205.41z"/>
							<path className="path-enter path-r" d="M343.9,233.33v-27.92h14.38c2.67,0,4.7,0.23,6.11,0.69s2.54,1.3,3.41,2.54c0.86,1.24,1.29,2.75,1.29,4.52
								c0,1.55-0.33,2.88-0.99,4.01c-0.66,1.12-1.57,2.04-2.72,2.73c-0.74,0.45-1.75,0.81-3.03,1.11c1.03,0.34,1.78,0.69,2.25,1.03
								c0.32,0.23,0.78,0.72,1.38,1.47c0.6,0.75,1,1.33,1.21,1.73l4.18,8.09h-9.75L357,224.8c-0.58-1.1-1.1-1.82-1.56-2.15
								c-0.62-0.43-1.33-0.65-2.11-0.65h-0.76v11.33H343.9z M352.57,216.72h3.64c0.39,0,1.16-0.13,2.29-0.38c0.57-0.11,1.04-0.41,1.4-0.88
								c0.36-0.47,0.54-1.01,0.54-1.62c0-0.9-0.29-1.59-0.86-2.08c-0.57-0.48-1.64-0.72-3.22-0.72h-3.79V216.72z"/>
							<path className="path-enter path-chevron-right" d="M407.96,231.18l-21.65-8.98v-5.65l21.65-9v6.76l-13.16,5.07l13.16,5.07V231.18z"/>
						</clipPath>
					</defs>
				</svg>

				<video className="sm-landing-video" src="/video/supermilk-entry-bg.mp4" autoPlay="true" muted="true" loop="true" playsinline="true"></video>

				<div className="sm-landing-enter-fill js-enter-fill"></div>

			</div>

			<a className="sm-landing-enter-button js-enter-button"></a>

      	</div>
    );
};

export default LandingPage;