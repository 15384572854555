const Release = ( { title, slug, year, format, label, bcID, bcType, info  } ) => {
    
    const thumbnail = '/images/supermilk--' + slug + '.jpg'
    const embed = 'https://bandcamp.com/EmbeddedPlayer/' + bcType + '=' + bcID + '/size=large/bgcol=000000/linkcol=EADDB6/tracklist=false/artwork=small/transparent=true/'
    const link = 'https://supermilk4u.bandcamp.com/' + bcType + '/' + slug;

    return (
        <div className="row justify-space-between">
            <div className="col-md-5 mb-2 mb-md-4">
                <img width={500} height={500} src={thumbnail} className="fluid d-block" alt={title} />
            </div>
            <div className="col-md-7 d-flex align-items-center mb-4">
                <div className="ps-md-2 w-100">
                    <h3>{title}</h3>
                    <h4>{year} // {format} // {label}</h4>
                    <p>{info}</p>
                    <iframe id={ 'fr-' + slug } style={{border: 0, width: "100%", height: 120}} src={embed} seamless>
                        <a href={link}>{title} by Supermilk</a>
                    </iframe>
                </div>
            </div>
        </div>
    )
}
export default Release