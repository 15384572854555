// src/pages/index.js
import LandingPage from './Landing.jsx';
import HomePage from './Home.jsx';
import AboutPage from './About.jsx';
import MusicPage from './Music.jsx';
import ShowsPage from './Shows.jsx';
import VideoPage from './Video.jsx';
// Import other pages as needed

const Pages = {
    landing: {
        name: "Landing",
        path: "",
        component: LandingPage,
        inNav: false
    },
    home: {
        name: "Home",
        path: "home",
        component: HomePage,
        inNav: false
    },
    about: {
        name: "About",
        path: "about",
        component: AboutPage
    },
    music: {
        name: "Music",
        path: "music",
        component: MusicPage
    },
    video: {
        name: "Video",
        path: "video",
        component: VideoPage
    },
    shows: {
        name: "Shows",
        path: "shows",
        component: ShowsPage
    },
};

export default Pages;