// Modules
import Navigation from "./modules/navigation.jsx";
// Pages
import Pages from './pages/pages.jsx';
import PageHandler from './pages/_PageHandler.jsx';
// Hooks
import { pageState, navState } from "./hooks/hooks.jsx";
// Functions
import { setInitPage, slugFromPath } from "./functions/functions.jsx";


// App
const App = () => {

  // Set consts and functions
  const { handlePageChange, handleURLChange, customNavigate, currentPage } = pageState( setInitPage, Pages ),
        { returnState } = navState( 'closed' ),
        { BrowserRouter, Routes, Route } = ReactRouterDOM,
        pageObjects = Object.entries( Pages );

  // Listen for back/forward browser actions
  window.addEventListener('popstate', () => {
    handlePageChange( slugFromPath() )
  }); 

  
  return (
    
    <div>
      <div className="bg"></div>
      <Navigation pages={ pageObjects } currentPage={ currentPage } navigate={ customNavigate } onURLChange={ handleURLChange } toggle={ returnState } />
      <div className="container container-page">
        <BrowserRouter>
          <Routes>
          { pageObjects.map( ([ key, page ] ) => (
              <Route 
                key={ key }
                path={ `/${ page.path }` }
                element={ <PageHandler currentPage={ currentPage }
                pages={ Pages }
                onPageChange={ handlePageChange } />}
              />
            ) ) }
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
