import Release from "../components/release.jsx";

const Discography = () => {

    return (

        <div className="col-md-9">

            <Release 
                title="High Precision Ghosts"
                slug="high-precision-ghosts"
                year="2024"
                format="LP"
                label="Specialist Subject Records"
                bcID="3336809856"
                bcType="album"
                info="Eco-mix colour vinyl. 300 copies."
            />

            <Release 
                title="Ocean / Sense"
                slug="ocean"
                year="2023"
                format="single"
                label="Sparse City"
                bcID="4218324902"
                bcType="track"
                info="Digital only."
            />

            <Release 
                title="Jessica's Army / Ribcage"
                slug="jessicas-army-ribcage"
                year="2023"
                format="single"
                label="Sparse City"
                bcID="1447462011"
                bcType="album"
                info="Lathe-cut transparent 7&quot;. Limited to 30 copies."
            />

            <Release 
                title="Live From Rad Apples"
                slug="live-from-rad-apples"
                year="2022"
                format="live album"
                label="Specialist Subject Records"
                bcID="2654182649"
                bcType="album"
                info="Mint green cassette. Limited to 30 copies."
            />

            <Release 
                title="Four by Three"
                slug="four-by-three"
                year="2021"
                format="LP"
                label="Specialist Subject Records"
                bcID="965566172"
                bcType="album"
                info="Blue & white swirl/Yellow colour vinyl. 250 copies of each."
            />

            <Release 
                title="Death Is the Best Thing for You Now"
                slug="death-is-the-best-thing-for-you-now"
                year="2020"
                format="LP"
                label="Keroleen Records"
                bcID="917034330"
                bcType="album"
                info="Transparent Red colour vinyl. Limited to 120 copies."
            />

            <Release 
                title="Rare Delusions"
                slug="rare-delusions"
                year="2019"
                format="EP"
                label="Keroleen Records"
                bcID="3198814811"
                bcType="album"
                info="Baby blue cassette. Limited to 30 copies."
            />

            <Release 
                title="Hello? Yes this is Supermilk..."
                slug="hello-yes-this-is-supermilk"
                year="2017"
                format="EP"
                label="Keroleen Records"
                bcID="1321972453"
                bcType="album"
                info="Mini CD. Limited to 30 copies."
            />
        </div>
    )
}
export default Discography;