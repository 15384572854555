
// native: useState
// accepts two values:
// 1. reading state
// 2. updating state
// e.g. const [currentPage, setCurrentPage] = useState(0);
export const useState = React.useState;

export const useEffect = React.useEffect;

// custom: pageState
// updates current page state from slug
// for use with nav and page handler
export const pageState = ( initialPage, pages ) => {

    const [currentPage, setCurrentPage] = useState( initialPage );

    const handlePageChange = ( slug ) => {
        document.querySelector( '#root' ).classList.add( 'hide-overflow-x' );
        document.querySelector( '.container-page' ).classList.add( 'transitioning' );
        setTimeout( () => {
            window.scrollTo( { top: 0, behavior: "instant" } )
            setCurrentPage( slug )
            document.querySelector( '.container-page' ).classList.remove( 'transitioning' );
        }, 300)
        setTimeout( () => {
            document.querySelector( '#root' ).classList.remove( 'hide-overflow-x' );
        }, 600)
    };

    const handleURLChange = ( slug ) => {
        history.pushState( null, null, pages[ slug ][ 'path' ] );
    };

    const customNavigate = ( slug ) => {
        handlePageChange( slug )
        handleURLChange( slug )
    }

    return { handlePageChange, handleURLChange, customNavigate, currentPage };
};


// custom: toggleNav
export const navState = ( toggleState ) => {

    const [readState, updateState] = useState( toggleState );

    const returnState = ( isNavLink ) => {

        if ( readState == 'open' ) {

            updateState( 'closed' )
            document.querySelector( 'nav' ).classList.remove( 'active' );
            document.querySelector( 'body' ).classList.remove( 'hide-overflow' );
        } else {

            updateState( 'open' )
            if ( !isNavLink ) {
                document.querySelector( 'nav' ).classList.add( 'active' );
                document.querySelector( 'body' ).classList.add( 'hide-overflow' );
            }
        }
    };

    return { returnState };
};



// custom: addOne
// very simple test hook using useState
// to increase stored number by one
export const addOne = ( number ) => {

    const [readNumber, updateNumber] = useState( number );

    const returnNumber = () => {

        const increasedNumber = readNumber + 1;
        updateNumber( increasedNumber );
        console.log( readNumber );
        return readNumber;
    };

    return { returnNumber };
};