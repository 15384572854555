// Hooks

import Socials from "../components/socials.jsx";

const Navigation = ({ pages, currentPage, navigate, toggle }) => {
  let navClass;
    if ( currentPage === 'landing' ) {
      navClass = 'hide';
    }
    return (
      <nav className={ navClass }>
        <div className="bg"></div>
        <div className="container">
          <div className="sm-nav-logo">
            <a onClick={() => {
                    navigate('home')
                    toggle(true)
                  }}><img src="./images/supermilk-logo.svg" alt="Supermilk" /></a>
          </div>
          <div className="sm-nav-menu">
            <ul>
              {pages.filter(([key, page]) => page.inNav !== false).map(([key, page]) => (
                <li key={key}>
                  <a onClick={() => {
                    navigate(key)
                    toggle(true)
                  }}>{page.name}</a>
                </li>
              ))}
              <li><a href="https://supermilk4u.bandcamp.com/merch" target="_blank">Merch</a></li>
              <li><a href="mailto:supermilk.band@gmail.com" target="_blank">Contact</a></li>
              <li className="d-block d-lg-none">
                <Socials />
              </li>
            </ul>
          </div>
          <div className="sm-nav-socials d-none d-lg-flex">
            <Socials />
          </div>
          <div className="sm-nav-menu-toggle d-flex d-lg-none align-items-center" onClick={() => { toggle() }}>
              <div className="hamburger">
                <span></span>
                <span></span>
                <span></span>
              </div>
          </div>
        </div>
      </nav>
    );
};

export default Navigation;