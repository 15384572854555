import Polaroids from "../modules/polaroids.jsx";

const VideoPage = () => {
    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-12 text-align-center mb-4">
                    <h1>Video</h1>
                </div>
                <div className="col-md-9 mb-4">
                    <div className="sm-embed-16x9">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/HWpOwTndauU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="col-md-9 mb-4">
                    <div className="sm-embed-16x9">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/XiStX4BymU8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="col-md-9 mb-4">
                    <div className="sm-embed-16x9">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/4G1nhxvzv00" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="col-md-9 mb-4">
                    <div className="sm-embed-16x9">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/bq3q-XmABx8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <Polaroids />
        </div>
    )
};
export default VideoPage;