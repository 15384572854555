import Polaroids from "../modules/polaroids.jsx";

const HomePage = () => {
    return (
        <div>
            <div className="row">
                <div className="col-md-7 col-lg-6 mb-2 mb-md-4">
                    <img width={628} height={628} src="images/supermilk--high-precision-ghosts.jpg" className="fluid" />
                </div>
                <div className="col-md-5 col-lg-6 d-flex mb-4 justify-content-center flex-column align-items-center text-align-center">
                    <h1>
                        <img width={550} height={550} src="images/high-precision-ghosts-text.svg" className="fluid d-block" />
                    </h1>
                    <h3 className="mt-2">
                        The new album <br className="d-block d-xl-none" />from Supermilk<br  />
                        <br className="d-block d-xl-none" />
                        Available now <br className="d-block d-xl-none" />on LP and Digital
                    </h3>
                    <iframe className="mt-4 ps-4 pe-4 d-block d-md-none d-lg-block" style={{maxWidth: 500, border: 0, width: "100%", height: 120}} src="https://bandcamp.com/EmbeddedPlayer/album=3336809856/size=large/bgcol=000000/linkcol=EADDB6/tracklist=false/artwork=small/transparent=true/" seamless><a href="https://supermilk4u.bandcamp.com/album/high-precision-ghosts">High Precision Ghosts by Supermilk</a></iframe>
                    <img width={150} height={86} src="images/specialist-subject-logo.png" className="mt-4 fluid d-block ssr-logo" />
                </div>
            </div>
            <Polaroids />
        </div>
    )
};
export default HomePage;