import Polaroids from "../modules/polaroids.jsx";
import Discography from "../modules/discography.jsx";

const MusicPage = () => {
    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-12 text-align-center mb-4">
                    <h1>Music</h1>
                </div>
                <Discography />
            </div>
            <Polaroids />
        </div>
    )
};
export default MusicPage;