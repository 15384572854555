const Polaroids = () => {
    return (
        <div className="row mt-2 mb-2">
            <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                <img src="images/jake-polaroid.png" className="fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                <img src="images/em-polaroid.png" className="fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                <img src="images/charlie-polaroid.png" className="fluid" />
            </div>
            <div className="col-6 col-lg-3 mb-4 mb-lg-0">
                <img src="images/jay-polaroid.png" className="fluid" />
            </div>
        </div>
    )
}
export default Polaroids